import React from "react";

import { Layout } from "../components/layout";
import { SEO } from "../components/seo";

export const NotFoundPage = () => (
  <Layout>
    <SEO title="404 - Not found" />
    <h1 style={{textAlign: 'center'}}>404 - Not Found</h1>
  </Layout>
);

export default NotFoundPage;
